import React, { Component, ReactElement, ReactNode } from "react";
import {
  CardActions,
  CardContent,
  Chip,
  Container,
  Fab,
  Fade,
  IconButton,
  isWidthUp,
  Snackbar,
  StyledComponentProps,
  StyleRules,
  Theme,
  Tooltip,
  Typography,
  useScrollTrigger,
  WithWidth,
  withWidth,
  Zoom,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import "firebase/storage";
import "firebase/firestore";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Pagination from "@material-ui/lab/Pagination";
import { Alert, PaginationItem, Skeleton } from "@material-ui/lab";
import { Link, Route, withRouter } from "react-router-dom";
import Box from "@material-ui/core/Box";
import withStyles from "@material-ui/core/styles/withStyles";
import { RouteComponentProps } from "react-router";
import axios from "axios";
import firebase from "firebase";
import { GetApp, KeyboardArrowUp } from "@material-ui/icons";
import Landing from "./Landing";
import Cookies from "universal-cookie";
import KeyboardEventHandler from "react-keyboard-event-handler";
import { SnackbarCloseReason } from "@material-ui/core/Snackbar/Snackbar";

const styles = (theme: Theme): StyleRules => ({
  container: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: theme.spacing() * 3,
  },
  memeContent: {
    paddingBottom: 0,
  },
  memeCard: {
    marginTop: theme.spacing() * 3,
    marginLeft: "auto",
    marginRight: "auto",
    display: "inline-block",
  },
  memeHeader: {
    width: "calc(100% - 32px)",
    [theme.breakpoints.up("sm")]: {
      width: 600,
    },
    marginBottom: theme.spacing(),
  },
  memeTitle: {
    marginBottom: theme.spacing(0.5),
  },
  memeTag: {
    margin: theme.spacing(0.5),
    marginLeft: 0,
  },
  memeActions: {
    paddingLeft: theme.spacing(2),
    position: "relative",
    justifyContent: "space-between",
  },
  memeOriginTypo: {
    maxWidth: "60vw",
    [theme.breakpoints.up("sm")]: {
      maxWidth: 360,
    },
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  memeOrigin: {
    color: theme.palette.text.secondary,
  },
  img: {
    margin: "0 auto",
    display: "block",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 600,
    },
  },
});

type Meme = Readonly<{
  id: string;
  type: string;
  src: string;
  title: string;
  image: string;
  category: string;
  tags: readonly string[];
}>;

type MemesProps = RouteComponentProps & StyledComponentProps & WithWidth;

interface MemesState {
  query: string | null;
  memes: Meme[];
  pageNumber: number;
  pageSize: number;
  totalPages: number;
  infoOpen: boolean;
  infoMessage: string;
  errorMessage?: string;
  first: boolean;
  last: boolean;
  loading: boolean;
  images: boolean[];
}

interface Props {
  children: React.ReactElement;
}

function scrollToTop(): void {
  document
    .querySelector("#toolbar")
    ?.scrollIntoView({ behavior: "smooth", block: "center" });
}

function ScrollTop(props: Props): ReactElement {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  return (
    <Zoom in={trigger}>
      <div
        onClick={scrollToTop}
        role="presentation"
        style={{ position: "fixed", bottom: 32, right: 32 }}
      >
        {children}
      </div>
    </Zoom>
  );
}

// noinspection SpellCheckingInspection
const CLICKAWAY = "clickaway";

class Memes extends Component<MemesProps, MemesState> {
  private cookies: Cookies;

  constructor(props: MemesProps) {
    super(props);

    this.state = {
      query: null,
      memes: [],
      pageNumber: 0,
      pageSize: 0,
      totalPages: 0,
      infoOpen: false,
      infoMessage: "",
      errorMessage: "",
      first: true,
      last: true,
      loading: false,
      images: [],
    };

    this.cookies = new Cookies();

    this.handleInfoClose = this.handleInfoClose.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.getQuery = this.getQuery.bind(this);
    this.getPageNumber = this.getPageNumber.bind(this);
    this.getPageSize = this.getPageSize.bind(this);
    this.getQueryForPage = this.getQueryForPage.bind(this);
  }

  handleInfoClose = (
    event?: React.SyntheticEvent,
    reason?: SnackbarCloseReason
  ): void => {
    if (reason === CLICKAWAY) {
      return;
    }

    this.setState({ infoOpen: false });
  };

  getQuery = (): URLSearchParams =>
    new URLSearchParams(this.props.location.search);

  getPageNumber = (): number =>
    parseInt(this.getQuery().get("page") ?? "1") - 1;

  getPageSize = (): number => parseInt(this.getQuery().get("size") ?? "10");

  getQueryString = (): string | null => this.getQuery().get("q");

  getQueryForPage = (pageNumber: number): string => {
    const search = this.getQuery();
    if (pageNumber && pageNumber > 1) {
      search.set("page", pageNumber.toString());
    } else {
      search.delete("page");
    }
    return search.toString();
  };

  componentDidMount(): void {
    this.queryMemes();
  }

  componentDidUpdate(prevProps: Readonly<MemesProps>): void {
    if (prevProps.location !== this.props.location) {
      this.queryMemes();
    }
  }

  handleKeyDown = (key: string, e: React.KeyboardEvent): void => {
    if (e.target instanceof HTMLInputElement) {
      return;
    }
    if (key === "left") {
      if (this.state.pageNumber) {
        this.props.history.push({
          search: this.getQueryForPage(this.state.pageNumber),
        });
      }
    } else if (key === "right") {
      if (!this.state.last) {
        this.props.history.push({
          search: this.getQueryForPage(this.state.pageNumber + 2),
        });
      }
    }
  };

  queryMemes(): void {
    const queryString = this.getQueryString();
    const pageNumber = this.getPageNumber();
    const pageSize = this.getPageSize();
    if (queryString && queryString.trim()) {
      this.setState({ infoOpen: false, loading: true });
      const trace = firebase.performance().trace("API_SEARCH");
      trace.putAttribute("query", queryString);
      trace.start();
      axios
        .get("https://api.szwagry.site/memes", {
          params: {
            query: queryString,
            page: pageNumber,
            size: pageSize,
            userId: this.cookies.get("userId"),
            sessionId: this.cookies.get("sessionId"),
          },
        })
        .then((response) => {
          trace.putMetric("totalElements", response.data.totalElements ?? 0);
          trace.stop();

          scrollToTop();

          const memes: Meme[] = [];
          const images: boolean[] = [];

          response.data.content?.forEach((item: Meme) => {
            memes.push(item);
            images.push(false);
          });
          const infoOpen = !!memes.length && this.state.query !== queryString;
          const infoMessage = `Znaleziono ${response.data.totalElements} memów.`;
          this.setState({
            query: queryString,
            memes: memes,
            pageNumber: response.data.pageNumber,
            pageSize: response.data.pageSize,
            totalPages: response.data.totalPages,
            infoOpen: infoOpen,
            infoMessage: infoMessage,
            errorMessage: undefined,
            first: response.data.first,
            last: response.data.last,
            loading: false,
            images: images,
          });
        })
        .catch(() => {
          scrollToTop();
          this.setState({
            query: queryString,
            memes: [],
            pageNumber: 0,
            pageSize: 0,
            totalPages: 0,
            loading: false,
            errorMessage: "Wystąpił błąd serwera!",
          });
        });
    } else {
      this.setState({
        query: null,
        memes: [],
        pageNumber: 0,
        pageSize: 0,
        totalPages: 0,
        errorMessage: undefined,
      });
    }
  }

  render(): ReactNode {
    const { classes } = this.props;
    return (
      <Container
        fixed={true}
        disableGutters={true}
        maxWidth="md"
        className={classes?.container}
      >
        {this.state.memes.map((meme, index) => (
          <Card key={index} className={classes?.memeCard} elevation={8}>
            <CardContent className={classes?.memeContent}>
              <Box className={classes?.memeHeader}>
                <Typography
                  component="h2"
                  variant="h5"
                  className={classes?.memeTitle}
                >
                  {this.state.loading ? (
                    <Skeleton height={32} style={{ margin: "0 0 4px" }} />
                  ) : (
                    meme.title
                  )}
                </Typography>
                {this.state.loading && (
                  <Skeleton height={32} style={{ margin: "0" }} />
                )}
                {!this.state.loading && meme.category && (
                  <Chip
                    label={meme.category}
                    color={"primary"}
                    size={"small"}
                    component={Link}
                    to={`/?q=category:${meme.category}`}
                    clickable
                    className={classes?.memeTag}
                  />
                )}
                {!this.state.loading &&
                  meme.tags?.map((tag) => (
                    <Chip
                      key={`meme-tag-${tag}`}
                      label={`#${tag}`}
                      color={"secondary"}
                      size={"small"}
                      component={Link}
                      to={`/?q=tags:${tag}`}
                      clickable
                      className={classes?.memeTag}
                    />
                  ))}
              </Box>
              <a
                href={meme.src}
                target="_blank"
                rel="noopener noreferrer"
                style={{ position: "relative", display: "inline-block" }}
              >
                <Fade in={!this.state.loading && this.state.images[index]}>
                  <img
                    className={classes?.img}
                    src={meme.image}
                    alt="meme"
                    onLoad={(): void => {
                      const images = [...this.state.images];
                      images[index] = true;
                      this.setState({ images: images });
                    }}
                  />
                </Fade>
                <Fade in={this.state.loading}>
                  <Skeleton
                    variant="rect"
                    style={{
                      position: "absolute",
                      top: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </Fade>
              </a>
            </CardContent>
            <CardActions className={classes?.memeActions}>
              <Typography color={"textSecondary"} hidden={this.state.loading}>
                #{this.state.pageNumber * this.state.pageSize + index + 1}
              </Typography>
              <Typography
                variant={"body2"}
                hidden={this.state.loading}
                className={classes?.memeOriginTypo}
              >
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={meme.src}
                  className={classes?.memeOrigin}
                >
                  {meme.src}
                </a>
              </Typography>
              <IconButton
                disabled={this.state.loading}
                target="_blank"
                rel="noopener noreferrer"
                href={`${meme.image}?attachment`}
              >
                <GetApp />
              </IconButton>
            </CardActions>
          </Card>
        ))}

        {!!this.state.memes.length && (
          <Pagination
            page={this.state.pageNumber + 1}
            count={this.state.totalPages}
            size={"large"}
            style={{ margin: "24px auto 0" }}
            renderItem={(item): ReactNode => (
              <PaginationItem
                component={Link}
                to={"/?" + this.getQueryForPage(item.page)}
                {...item}
              />
            )}
          />
        )}

        {isWidthUp("md", this.props.width) && (
          <Tooltip title={"Możesz również użyć strzałki na klawiaturze."}>
            <Zoom in={!!this.state.memes.length}>
              <Fab
                color={"primary"}
                style={{
                  position: "fixed",
                  right: "50%",
                  marginRight: 364,
                  top: "50%",
                  marginTop: -28,
                }}
                disabled={this.state.first}
                component={Link}
                to={"/?" + this.getQueryForPage(this.state.pageNumber)}
              >
                <ArrowBackIosIcon />
              </Fab>
            </Zoom>
          </Tooltip>
        )}
        {isWidthUp("md", this.props.width) && (
          <Tooltip title={"Możesz również użyć strzałki na klawiaturze."}>
            <Zoom in={!!this.state.memes.length}>
              <Fab
                color={"primary"}
                style={{
                  position: "fixed",
                  left: "50%",
                  marginLeft: 364,
                  top: "50%",
                  marginTop: -28,
                }}
                disabled={this.state.last}
                component={Link}
                to={"/?" + this.getQueryForPage(this.state.pageNumber + 2)}
              >
                <ArrowForwardIosIcon />
              </Fab>
            </Zoom>
          </Tooltip>
        )}

        <KeyboardEventHandler
          handleKeys={["left", "right"]}
          onKeyEvent={this.handleKeyDown}
          handleFocusableElements={true}
        />

        {!this.state.memes.length && this.state.query && !this.state.loading && (
          <Alert
            severity={this.state.errorMessage ? "error" : "warning"}
            variant={"filled"}
            style={{ maxWidth: 600, margin: "32px auto 0" }}
          >
            {this.state.errorMessage ||
              `Nie znaleziono memów dla zapytania "${this.state.query}".`}
          </Alert>
        )}
        {!this.state.memes.length && (
          <Route>
            <Landing />
          </Route>
        )}

        <ScrollTop {...this.props}>
          <Fab
            color="secondary"
            size={isWidthUp("md", this.props.width) ? "medium" : "large"}
          >
            <KeyboardArrowUp />
          </Fab>
        </ScrollTop>

        <Snackbar
          open={this.state.infoOpen}
          onClose={this.handleInfoClose}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <Alert severity={"success"} variant={"filled"}>
            {this.state.infoMessage}
          </Alert>
        </Snackbar>
      </Container>
    );
  }
}

export default withRouter(withWidth()(withStyles(styles)(Memes)));
