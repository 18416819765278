import React, { Component, ReactNode } from "react";
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  StyledComponentProps,
  StyleRules,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import LinkIcon from "@material-ui/icons/Link";

class SearchQuery {
  title: string;
  query: string;
  image?: string;
  constructor(name: string, query: string, image?: string) {
    this.title = name;
    this.query = query;
    this.image = image;
  }
}

const SEARCH_QUERIES = [
  new SearchQuery("Witam", 'tags:Witam "ładna pani"', "witam.jpg"),
  new SearchQuery("Janusz", "janusz nosacz", "janusz.jpg"),
  new SearchQuery("BLM", "george floyd BLM", "george-floyd.jpg"),
  new SearchQuery("Koronawirus", "corona virus koronawirus", "koronawirus.jpg"),
  new SearchQuery("Rick & Morty", "rick morty", "rick-and-morty.jpg"),
  new SearchQuery("Kapitan Bomba", "kapitan bomba", "kapitan-bomba.jpg"),
  new SearchQuery("Ziobro", 'ziobro "przestań mi"', "ziobro.jpg"),
  new SearchQuery(
    "Chłopaki z baraków",
    "chłopaki z baraków",
    "chlopaki-z-barakow.jpg"
  ),
  new SearchQuery("Madka", "madka bombelek brajan", "madka.jpg"),
  new SearchQuery("JKM", "JKM", "jkm.jpg"),
  new SearchQuery("Grażyna", "grażyna grażyny", "grazyna.jpg"),
  new SearchQuery("Pjoter", "pjoter pioter", "pjoter.jpg"),
  new SearchQuery("Top Gear", "top gear", "top-gear.jpg"),
  new SearchQuery("Wiedźmin", "wiedźmin", "wiedzmin.jpg"),
  new SearchQuery("LOTR", "lotr", "lotr.jpg"),
  new SearchQuery("GTA", "gta", "gta.jpg"),
  new SearchQuery("Makłowicz", "makłowicz", "maklowicz.jpg"),
  new SearchQuery("NIE", '"tygodnik nie"', "nie.png"),
  new SearchQuery("PDK", "pdk", "pdk.jpg"),
  new SearchQuery("Julka", "julki julka", "julka.jpg"),
  new SearchQuery("Dragi", "ecstasy lsd dragi", "dragi.jpg"),
  new SearchQuery("Cygan", "cygan", "cygan.jpg"),
];

const styles = (theme: Theme): StyleRules => ({
  root: {
    width: 138,
    margin: theme.spacing(1),
  },
  media: {
    height: 138,
  },
  content: {
    padding: 8,
    paddingBottom: "8px !important",
  },
  infoBox: {
    [theme.breakpoints.up(446)]: {
      display: "flex",
      width: 446,
    },
    width: 292,
    margin: theme.spacing(1),
  },
  infoBoxAction: {
    [theme.breakpoints.up(446)]: {
      width: 243,
      height: 198,
    },
    width: 292,
    height: 238,
  },
  infoBoxActionImage: {
    [theme.breakpoints.up(446)]: {
      width: 243,
      height: 198,
    },
    width: 292,
    height: 238,
  },
  infoBoxLink: {
    color: theme.palette.secondary.contrastText,
  },
  infoBoxContent: {
    paddingRight: 8,
    paddingBottom: "0 !important",
    lineHeight: 1,
  },
});

class Landing extends Component<RouteComponentProps & StyledComponentProps> {
  render(): ReactNode {
    const { classes } = this.props;
    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={2}
        style={{ marginTop: 24 }}
      >
        <Card className={classes?.infoBox}>
          <CardActionArea
            component={Link}
            to={"/?q=ja+i+stary"}
            className={classes?.infoBoxAction}
          >
            <CardMedia
              image={"/static/images/info.gif"}
              className={classes?.infoBoxActionImage}
            />
          </CardActionArea>
          <CardContent className={classes?.infoBoxContent}>
            <Typography variant="body2">
              Wyszukiwarka memów rozpoznająca tekst na obrazkach!
              <br />
              Sprawdź:
            </Typography>
            <br />
            <Button
              startIcon={<LinkIcon />}
              variant={"contained"}
              component={Link}
              to={"/?q=ja+i+stary"}
              size={"small"}
              color={"secondary"}
              style={{ margin: "0 auto" }}
            >
              {'"ja i stary"'}
            </Button>
            <br />
            <br />
            <img
              src={"/static/images/jbzd.com.pl.png"}
              alt={"Logo jbzd.com.pl"}
              style={{ height: 32 }}
            />
            &nbsp;
            <img
              src={"/static/images/chamsko.pl.png"}
              alt={"Logo chamsko.pl"}
              style={{ height: 32 }}
            />
          </CardContent>
        </Card>
        {SEARCH_QUERIES.map((sq, idx) => (
          <Card key={idx} className={classes?.root}>
            <CardActionArea component={Link} to={`/?q=${sq.query}`}>
              <CardMedia
                className={classes?.media}
                image={`/static/images/${sq.image}`}
              />
            </CardActionArea>
            <CardContent className={classes?.content}>
              <Typography noWrap={true} variant="button" component="h2">
                {sq.title}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                noWrap={true}
              >
                {sq.query}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Grid>
    );
  }
}

export default withRouter(withStyles(styles)(Landing));
