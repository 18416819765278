import { withRouter } from "react-router-dom";
import InputBase from "@material-ui/core/InputBase";
import { InputBaseProps } from "@material-ui/core/InputBase/InputBase";
import React, { Component, ReactNode } from "react";
import { RouteComponentProps } from "react-router";
import firebase from "firebase";
import { Search } from "@material-ui/icons";

interface SearchBoxState {
  query: string;
}

class SearchBox extends Component<
  InputBaseProps & RouteComponentProps,
  SearchBoxState
> {
  constructor(props: InputBaseProps & RouteComponentProps) {
    super(props);

    this.state = {
      query: this.getCurrentQuery(),
    };

    this.getCurrentQuery = this.getCurrentQuery.bind(this);
    this.search = this.search.bind(this);
  }

  componentDidUpdate(): void {
    const queryString = this.getCurrentQuery();
    if (queryString !== this.state.query) {
      this.setState({ query: queryString });
      const input = document.querySelector<HTMLInputElement>("#search");
      if (input) {
        input.value = queryString;
      }
    }
  }

  getCurrentQuery = (): string =>
    new URLSearchParams(this.props.location.search).get("q") ?? "";

  search = (value: string): void => {
    if (value !== this.getCurrentQuery()) {
      firebase.analytics().logEvent("search", {
        search_term: value,
      });
      const searchParams = new URLSearchParams("");
      searchParams.set("q", value);
      this.props.history.push({ search: searchParams.toString() });
    }
  };

  render(): ReactNode {
    return (
      <InputBase
        id={"search"}
        defaultValue={this.state.query}
        onBlur={(e): void => this.search(e.target.value)}
        inputMode={"search"}
        startAdornment={<Search style={{ margin: "0 4px" }} />}
        onKeyUp={(e): void => {
          if (e.key === "Enter") {
            this.search(e.currentTarget.value);
          }
        }}
        {...this.props}
      />
    );
  }
}

export default withRouter(SearchBox);
