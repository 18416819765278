import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 632,
      md: 808,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    type: "dark",
    primary: {
      main: "#137CBD",
    },
    secondary: {
      main: "#d9822b",
    },
    contrastThreshold: 2,
    background: {
      paper: "#293742",
      default: "#30404d",
    },
  },
  shape: {
    borderRadius: 8,
  },
  spacing: 8,
});

export default theme;
