import React, { Fragment, ReactElement } from "react";
import Memes from "./components/Memes";
import { createBrowserHistory } from "history";
import { Route, Router } from "react-router-dom";
import firebase from "firebase";
import AppBar from "./components/AppBar";
import { Toolbar } from "@material-ui/core";
import Cookies from "universal-cookie";
import { v4 as uuidv4 } from "uuid";
import LogRocket from "logrocket";

const cookies = new Cookies();
let USER_ID = cookies.get("userId");
if (!USER_ID) {
  USER_ID = uuidv4();
  cookies.set("userId", USER_ID, {
    path: "/",
    secure: true,
    maxAge: 31104000,
  });
}

cookies.set("sessionId", uuidv4(), {
  path: "/",
  secure: true,
  domain: "szwagry.site",
});

LogRocket.init("ppl2wj/szwagry-site");
LogRocket.identify(USER_ID);

firebase.initializeApp({
  apiKey: "AIzaSyAUZTYo_2JLWNqvY9dgl-hbRg0mYO6N0Bo",
  authDomain: "szwagry-5a82f.firebaseapp.com",
  databaseURL: "https://szwagry-5a82f.firebaseio.com",
  projectId: "szwagry-5a82f",
  storageBucket: "szwagry-5a82f.appspot.com",
  messagingSenderId: "726173013759",
  appId: "1:726173013759:web:662bf8d39946be56365eca",
  measurementId: "G-J977SG4EY7",
});

const history = createBrowserHistory();
const analytics = firebase.analytics();
firebase.performance();

history.listen((location) => {
  analytics.logEvent("page_view", {
    page_location: location.pathname,
  });
});

function App(): ReactElement {
  return (
    <Fragment>
      <Router history={history}>
        <Route>
          <AppBar position="sticky" />
          <Toolbar id="toolbar" style={{ position: "absolute" }} />
          <Memes />
        </Route>
      </Router>
    </Fragment>
  );
}

export default App;
