import MuiAppBar, { AppBarProps } from "@material-ui/core/AppBar";
import {
  IconButton,
  StyledComponentProps,
  StyleRules,
  Theme,
  Toolbar,
  withStyles,
} from "@material-ui/core";
import SearchBox from "./SearchBox";
import { Clear } from "@material-ui/icons";
import React, { Component, ReactNode } from "react";
import { RouteComponentProps } from "react-router";
import { Link, withRouter } from "react-router-dom";

const styles = (theme: Theme): StyleRules => ({
  toolbar: {
    margin: "0 auto",
    display: "flex",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 600,
    },
  },
});

class AppBar extends Component<
  AppBarProps & RouteComponentProps & StyledComponentProps
> {
  render(): ReactNode {
    const { classes } = this.props;
    return (
      <MuiAppBar {...this.props}>
        <Toolbar
          variant="dense"
          disableGutters={true}
          className={classes?.toolbar}
        >
          <SearchBox placeholder="Szukaj" autoFocus={true} />
          <div style={{ flexGrow: 1 }} />
          <IconButton
            disabled={!new URLSearchParams(this.props.location.search).get("q")}
            component={Link}
            to={"/"}
          >
            <Clear />
          </IconButton>
        </Toolbar>
      </MuiAppBar>
    );
  }
}

export default withRouter(withStyles(styles)(AppBar));
